import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import linkStripper from '../utils/link-stripper';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import BlankSpace from '../components/blankSpace';
import Squiggle from '../components/squiggle';

//Image Import
import AwardsWave from '../images/tmp/awardsWave.svg';
import AwardsRightWave from '../images/tmp/awardsRightWave.svg';

const AwardsBlockContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const AwardsBlockContainerInner = styled.div`
  width: 100%;
  max-width: 1150px;
  @media (max-width: 1250px) {
    width: 90%;
  }
`;

const HeaderTitle = styled.h2`
  font-style: normal;
  font-family: DM Serif Text;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #404040;
  @media (max-width: 1000px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #e5e5e5;
`;

const ContentContainer = styled.div`
  display: flex;
  @media (max-width: 880px) {
    flex-wrap: wrap;
  }
`;

const AwardContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AwardContentContainerInner = styled.div`
  width: 100%;
`;

const TextContent = styled.div`
  max-width: 500px;
  margin-bottom: 50px;
  @media (max-width: 880px) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-family: DM Sans;
  font-size: 28px;
  line-height: 36px;
  color: #404040;
  margin-bottom: 20px;
`;

const Paragraph = styled.div`
  font-style: normal;
  font-family: DM Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #404040;
  width: 95%;
  max-width: 375px;
  @media (max-width: 880px) {
    max-width: 100%;
  }
`;

const AwardsItems = styled(motion.div)`
  width: 100%;
`;

const AwardItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const AwardSpacer = styled.div`
  border: 1px solid #e5e5e5;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
  }
`;

const AwardTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-family: DM Sans;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #404040;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
  @media (max-width: 880px) {
    max-width: 60%;
    text-align: left;
  }
`;

const AwardPosition = styled.div`
  font-style: normal;
  font-weight: 800;
  font-family: DM Sans;
  font-size: 18px;
  line-height: 36px;
  text-align: right;
  color: ${props => props.textColor};
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const AwardImage = styled.img`
  width: 300px;
  margin-right: 100px;
`;

const AwardImageContainer = styled.div`
  width: 290px;
  height: 380px;
  margin-right: 100px;
  position: relative;
  @media (max-width: 880px) {
    display: none;
  }
`;

const LeftSquiggle = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
  margin-left: -140px;
  transform: rotate(45deg);
  mix-blend-mode: multiply;
`;

const Spacer = styled.div`
  margin-top: 75px;
`;

const AwardsItemContainer = styled(motion.div)``;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.25,
      when: 'beforeChildren',
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class Awards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <AwardsBlockContainer>
          <AwardsBlockContainerInner>
            <React.Fragment>
              {this.props.title && this.props.title ? (
                <HeaderContainer>
                  <HeaderTitle>{this.props.title}</HeaderTitle>
                  <ButtonNotUnderlined
                    link={linkStripper(this.props.see_all_cta_link)}
                    color={this.props.titleColor}
                    arrowColor={this.props.awards_colour_theme}
                    buttonText={this.props.see_all_cta_title}
                    target={this.props.see_all_cta_link_target}
                  />
                </HeaderContainer>
              ) : (
                <Spacer />
              )}
              <ContentContainer>
                <AwardImageContainer>
                  <AwardImage src={this.props.image} />
                </AwardImageContainer>
                <AwardContentContainer>
                  <AwardContentContainerInner>
                    <TextContent>
                      <Title>{this.props.awards_title}</Title>
                      <Paragraph>{this.props.awards_paragraph}</Paragraph>
                    </TextContent>

                    <AwardsItems
                      variants={container}
                      initial="hidden"
                      animate={this.state.visible ? 'visible' : 'hidden'}
                    >
                      {this.props.items &&
                        this.props.items.map((award, index) => (
                          <AwardsItemContainer variants={item} key={index}>
                            <AwardItem key={index}>
                              <AwardTitle>{award.award_name.text}</AwardTitle>
                              <AwardPosition
                                textColor={this.props.awards_colour_theme}
                              >
                                {award.award_outcome.text}
                              </AwardPosition>
                            </AwardItem>
                            <AwardSpacer></AwardSpacer>
                          </AwardsItemContainer>
                        ))}
                    </AwardsItems>
                  </AwardContentContainerInner>
                </AwardContentContainer>
              </ContentContainer>
            </React.Fragment>
          </AwardsBlockContainerInner>
        </AwardsBlockContainer>
      </VisibilitySensor>
    );
  }
}

export default Awards;
